import { Controller } from '@hotwired/stimulus';

abstract class BaseIntersectionController extends Controller {
  protected observer?: IntersectionObserver;

  static targets = ['element'];

  initialize() {
    super.initialize();
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => this.observerFn(entry));
    }, this.intersectionObserverOptions);
  }

  observerFn(entry: IntersectionObserverEntry) {
    if (entry.isIntersecting) {
      this.elementIntersected(entry);
      this.observer?.unobserve(entry.target);
    }
  }

  elementTargetConnected(element: HTMLElement) {
    this.observer?.observe(element);
  }

  elementTargetDisconnected(element: HTMLElement) {
    this.observer?.unobserve(element);
  }

  disconnect() {
    super.disconnect();
    this.observer?.disconnect();
  }

  elementIntersected(entry: IntersectionObserverEntry) {}

  get intersectionObserverOptions(): IntersectionObserverInit {
    return { threshold: 0.2 };
  }
}

export { BaseIntersectionController };
